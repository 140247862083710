import { MutationTree } from "vuex";
import { ForgotPwdState } from "./state";

export interface ForgotPwdMutations {
  setEmailAddress(state: ForgotPwdState, emailAddress: string): void;
  setMobileNumber(state: ForgotPwdState, mobileNumber: string): void;
}

export const mutations: MutationTree<ForgotPwdState> = {
  setEmailAddress(state: ForgotPwdState, emailAddress: string): void {
    state.emailAddress = emailAddress;
  },
  setMobileNumber(state: ForgotPwdState, mobileNumber: string): void {
    state.mobileNumber = mobileNumber;
  }
};
