import { HttpService } from "@/services/HttpService";
import { StatusType } from "@/types";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { ActionContext } from "vuex";
import { StateInterface } from "../state";
import { DataTakeoutState } from "./state";

export interface DataTakeoutActions {
  dl(
    context: ActionContext<DataTakeoutState, StateInterface>
  ): Promise<unknown>;
}
export const actions: DataTakeoutActions = {
  dl(
    context: ActionContext<DataTakeoutState, StateInterface>
  ): Promise<AxiosResponse> {
    context.commit("app/setStatus", StatusType.downloadingDataTakeout, {
      root: true
    });

    return new Promise((resolve, reject) => {
      HttpService.get(`/persons/dataTakeout/${context.state.otp}`, {
        responseType: "blob"
      } as AxiosRequestConfig<any>)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        })
        .finally(() =>
          context.commit("app/setStatus", null, {
            root: true
          })
        );
    });
  }
};
