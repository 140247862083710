import { ResetPwdState } from "./state";

export interface ResetPwdGetters {
  otp(state: ResetPwdState): null | string;
}

export const getters: ResetPwdGetters = {
  otp(state: ResetPwdState): null | string {
    return state.otp;
  }
};
