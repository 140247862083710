import { MutationTree } from "vuex";
import { ResetPwdState } from "./state";

export interface ResetPwdMutations {
  setOtp(state: ResetPwdState, password: string): void;
  setNewPassword(state: ResetPwdState, password: string): void;
}

export const mutations: MutationTree<ResetPwdState> = {
  setOtp(state: ResetPwdState, otp: string): void {
    state.otp = otp;
  },
  setNewPassword(state: ResetPwdState, password: string): void {
    state.password = password;
  }
};
