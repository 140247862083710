import { MutationTree } from "vuex";
import { DemoAccountState } from "./state";

export interface DemoAccountMutations {
  setShowDomainExists(state: DemoAccountState, showDomainExists: boolean): void;
}

export const mutations: MutationTree<DemoAccountState> = {
  setShowDomainExists(
    state: DemoAccountState,
    showDomainExists: boolean
  ): void {
    state.showDomainExists = showDomainExists;
  }
};
