
import Component from "vue-class-component";
import PolicyDialog from "@/components/registration/steps/PolicyDialog.vue";
import CenteredContent from "@/components/CenteredContent.vue";
import { Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";
import DemoAccountDomainExistsDialog from "@/components/registration/DemoAccountDomainExists.vue";

@Component({
  components: { PolicyDialog, DemoAccountDomainExistsDialog, CenteredContent }
})
export default class RegisterView extends Mixins(Config) {
  name = "RegisterView";

  title = this.$t("register.title");

  async created() {
    //
    // load initial registration flow and save in registration store
    //
    const regFlow = await this.getRegistrationFlow();
    this.$store.commit("registration/setRegistrationFlow", regFlow);
  }
}
