
import Component from "vue-class-component";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import VueI18n from "vue-i18n";
import TextField from "@/components/TextField.vue";
import Config from "@/mixins/config";

@Component({
  components: {
    TextField
  }
})
export default class MobileNumberInput extends Mixins(Config) {
  @Prop({ default: "" })
  private value!: string;

  @Prop({ default: false })
  public enabled!: boolean;

  @Prop({ default: false })
  plainInput!: boolean;

  public mobileNumberRules: Array<
    (value: string) => true | string | VueI18n.TranslateResult
  > = [(value: string) => !!value || this.$t("mobileNumber.error.missing")];

  public mobileNumber = this.value || "";

  validate(): void {
    //
  }

  /**
   * Sets an email rule with a localization code 'mobileNumber.error.{passed value}'
   */
  error(error: string): void {
    this.mobileNumberRules.push(() => this.$t("mobileNumber.error." + error));
  }

  push(rule: (value: string) => true | string | VueI18n.TranslateResult): void {
    this.mobileNumberRules.push(rule);
  }

  pop(): void {
    this.mobileNumberRules.pop();
  }

  // parent components can react to changes
  @Watch("mobileNumber")
  onMobileNumberChanged() {
    this.$emit("input", this.mobileNumber);
  }

  async created() {
    const globalConfig = this.getGlobalConfig();
    this.push(
      (value: string) =>
        globalConfig.inputFields.mobileNumber.regex.test(value) ||
        this.$t("mobileNumber.error.wrong")
    );
  }
}
