import { BasicInfoConfig } from "@/components/registration/steps/BasicInfo.config";
import { ChooseOrgUnitConfig } from "@/components/registration/steps/ChooseOrgUnit.config";
import { OtherInfoConfig } from "@/components/registration/steps/OtherInfo.config";
import { PolicyDialogConfig } from "@/components/registration/steps/PolicyDialog.config";
import { RegistrationState } from "@/store/registration/state";
import { RegistrationFlow, RegistrationStepEnum } from "@/types";

/**
 * registrationFlow for customer: ias
 */
const registrationFlow: RegistrationFlow = {
  globalConfig: {
    inputFields: {
      passwords: {
        enabled: true
      },
      emailAddress: {
        enabled: true,
        regex: /^(?!@)[^@]+@[^@]+(?!@)$/
      },
      mobileNumber: {
        enabled: false,
        regex: /^\+\d{1,3}(\(0\)\d{3}|\d{3,4}|\(\d{3}\))\d{7}$/
      }
    },
    demoAccounts: {
      // key: path for demo account registration
      "/demo-account": {
        id: "demo-accounts-unit",
        title: "register.demoAccount.card-title"
      },
      "/demo-account-EAP": {
        id: "demo-accounts-unit-eap",
        title: "register.demoAccountEap.card-title"
      }
    }
  },
  [RegistrationStepEnum.BASIC_INFO]: {
    name: "basicinfo",
    config: {
      component: "basic-info",
      passwords: {
        enabled: true
      },
      firstName: { enabled: true },
      lastName: { enabled: true },
      emailAddress: { enabled: true },
      mobileNumber: { enabled: false }
    } as BasicInfoConfig,
    previousStep: null, // first step
    nextStep: RegistrationStepEnum.CHOOSE_ORG_UNIT
  },
  //
  // step 2 (conditional)
  //
  [RegistrationStepEnum.CHOOSE_ORG_UNIT]: {
    name: "orgunit",
    config: {
      component: "choose-org-unit",
      orgUnit: { enabled: true }
    } as ChooseOrgUnitConfig,
    preCondition: (regState: RegistrationState) => {
      return (regState.orgUnits?.length ?? 0) > 1;
    },
    previousStep: RegistrationStepEnum.BASIC_INFO,
    nextStep: RegistrationStepEnum.OTHER_INFO
  },
  //
  // step 3
  //
  [RegistrationStepEnum.OTHER_INFO]: {
    name: "otherinfos",
    config: {
      component: "other-info",
      gender: { enabled: true },
      preferredLanguage: { enabled: true },
      yearOfBirth: { enabled: true },
      employeeAssignment: { enabled: true },
      workAtDesk: { enabled: true }
    } as OtherInfoConfig,
    previousStep: RegistrationStepEnum.CHOOSE_ORG_UNIT,
    nextStep: RegistrationStepEnum.POLICY // last step
  },
  [RegistrationStepEnum.POLICY]: {
    name: "policy",
    config: {
      component: "PolicyDialog",
      newsletter: true
    } as PolicyDialogConfig,
    previousStep: RegistrationStepEnum.OTHER_INFO,
    nextStep: null // last step
  }
};

export default registrationFlow;
