import { Status } from "@/types";
import { AppState } from "./state";

export interface AppGetters {
  status(state: AppState): Status;
}

export const getters: AppGetters = {
  status(state: AppState): Status {
    return state.status;
  }
};
