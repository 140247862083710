import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { AppModule } from "./app/module";
import { DataTakeoutModule } from "./dataTakeout/module";
import { ForgotPwdModule } from "./forgotPassword/module";
import { RegistrationModule } from "./registration/module";
import { ResetPwdModule } from "./resetPassword/module";
import { StateInterface } from "./state";
import { DemoAccountModule } from "./demoAccount/module";

Vue.use(Vuex);

const strict = process.env.NODE_ENV !== "production"; // strict mode should not be used on the production

const storeDefinition: StoreOptions<StateInterface> = {
  state: {},
  modules: {
    app: AppModule,
    registration: RegistrationModule,
    demoAccount: DemoAccountModule,
    forgotPassword: ForgotPwdModule,
    resetPassword: ResetPwdModule,
    dataTakeout: DataTakeoutModule
  },
  plugins: [],
  strict
};

export const store = new Vuex.Store<StateInterface>(storeDefinition);

export default store;
