
import OrgUnitSelect from "@/components/registration/steps/OrgUnitSelect.vue";
import { focus } from "@/directives/focus";
import {
  RegistrationForm,
  RegistrationStepEnum,
  StatusType,
  VForm
} from "@/types";
import Vue from "vue";
import Component from "vue-class-component";
import FormContainer from "@/components/registration/FormContainer.vue";
import { Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";
import { ChooseOrgUnitConfig } from "./ChooseOrgUnit.config";

@Component({
  components: {
    OrgUnitSelect,
    FormContainer
  },
  directives: {
    focus
  }
})
export default class ChooseOrgUnit extends Mixins(Config) {
  name = "RegisterForm-ChooseOrgUnit";

  form: RegistrationForm = {
    orgUnit: "",
    ...this.$store.getters["registration/form"]
  };

  formConfig: ChooseOrgUnitConfig = {
    component: "ChooseOrgUnit",
    orgUnit: { enabled: true }
  };

  // Load config
  async created() {
    this.formConfig = this.getFormConfig(RegistrationStepEnum.CHOOSE_ORG_UNIT);
  }

  // status of the form
  valid = false;

  orgUnitRules = [
    (value: string) => !!value || this.$t("register.orgUnits.error")
  ] as Array<any>;

  /**
   * The following getter helps to prevent nasty warnings of the parser
   * during Unit tests.
   */
  get orgUnitEnabled(): boolean {
    return this.formConfig.orgUnit.enabled;
  }

  get loading(): boolean {
    return this.$store.getters["app/status"] === StatusType.loadingOrgUnit;
  }

  async validateForm() {
    (this.$refs.form as VForm).validate();
    await Vue.nextTick();
    if (this.valid) {
      this.nextStep();
    }
  }

  async previousStep(): Promise<void> {
    this.$store.commit("registration/setForm", this.form);
    this.$store.commit("app/setStatus", null);
    await this.$store.dispatch("registration/previousStep");
  }

  async nextStep(): Promise<void> {
    this.$store.commit("registration/setForm", this.form);
    this.$store.commit("app/setStatus", null);
    await this.$store.dispatch("registration/nextStep");
  }

  get nextButtonEnabled(): boolean {
    return this.valid && this.selectValid && !this.loading;
  }

  selectValid = false;
  handleValidChange(newValue: boolean) {
    this.selectValid = newValue;
  }
}
