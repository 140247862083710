
import { focus } from "@/directives/focus";
import { HttpService } from "@/services/HttpService";
import { Invitation, RegistrationForm, StatusType } from "@/types";
import { Component } from "vue-property-decorator";
import RegisterForm from "./RegisterForm.vue";
import BasicInfoInvitee from "@/components/registration/BasicInfoInvitee.vue";

@Component({
  components: {
    BasicInfoInvitee
  },
  directives: {
    focus
  }
})
export default class InviteeRegisterForm extends RegisterForm {
  name = "InviteeRegisterForm";

  invitation: Invitation = {
    mailAddress: "",
    orgUnitId: "",
    orgUnitName: "",
    senderDisplayName: ""
  };

  form: RegistrationForm = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    otp: this.$route.params.otp
  };

  async mounted() {
    this.$store.commit("app/setStatus", StatusType.gettingInvitation);

    const invitationUrl = process.env.VUE_APP_INVITATION_APP_URL || null;

    const response = await HttpService.get(
      invitationUrl + "/invitations/" + this.form.otp
    ).catch((error) => {
      if (error.response.status === 404) {
        this.$router.push({ name: "registration-inviteeExists" });
      } else {
        this.$store.commit("app/setStatus", StatusType.error);
      }
    });
    if (response && response.status === 200) {
      this.invitation = response.data;
      // This is needed for RegisterForm#validEmailAdress()
      this.form.emailAddress = this.invitation.mailAddress;
      this.$store.commit("app/setStatus", null);
    }
  }
}
