//import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { loadCustomerRoutes } from "./router.utils";

Vue.use(VueRouter);

const routes: RouteConfig[] =
  loadCustomerRoutes(process.env.VUE_APP_CUSTOMER ?? "default") ?? [];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
});

export default router;
