import {
  OrgUnit,
  RegistrationFlow,
  RegistrationForm,
  RegistrationStepEnum
} from "@/types";
import { MutationTree } from "vuex";
import { RegistrationState } from "./state";

export interface RegistrationMutations {
  setForm(state: RegistrationState, form: RegistrationForm): void;
  setStep(state: RegistrationState, step: RegistrationStepEnum): void;
  setDemoAccount(state: RegistrationState, demoAccount: string | null): void;
  setRegistrationFlow(
    state: RegistrationState,
    registrationFlow: RegistrationFlow | undefined
  ): void;
  setOrgUnits(state: RegistrationState, orgUnits: Array<OrgUnit>): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExtensionUpdater(state: RegistrationState, extensionUpdater: any): void;
}

export const mutations: MutationTree<RegistrationState> = {
  setForm(state: RegistrationState, form: RegistrationForm): void {
    state.form = { ...form };
  },
  setRouteNames(state: RegistrationState, routeNames: Array<string>) {
    state.routeNames = routeNames;
  },
  setStep(state: RegistrationState, step: RegistrationStepEnum): void {
    state.step = step;
  },
  setOrgUnits(state: RegistrationState, orgUnits: Array<OrgUnit>): void {
    state.orgUnits = orgUnits.map((orgUnit) => {
      return { ...orgUnit };
    });
  },
  setDemoAccount(state: RegistrationState, demoAccount: string): void {
    state.demoAccount = demoAccount;
  },
  setRegistrationFlow(
    state: RegistrationState,
    registrationFlow: RegistrationFlow
  ): void {
    state.registrationFlow = registrationFlow ?? [];
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExtensionUpdater(state: RegistrationState, extensionUpdater: any): void {
    state.extensionUpdater = extensionUpdater;
  }
};
