module.exports = {
  light: {
    primary: "#258BD8",
    secondary: "#FF992C",
    accent: "#FF992C",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107"
  },
  // used for input fields
  dense: false
};
