import { Status } from "@/types";
import { MutationTree } from "vuex";
import { AppState } from "./state";

export interface AppMutations {
  setStatus(state: AppState, status: Status): void;
}

export const mutations: MutationTree<AppState> = {
  setStatus(state: AppState, status: Status): void {
    state.status = status;
  }
};
