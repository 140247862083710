
import i18n from "@/i18n";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class DemoAccountDomainExists extends Vue {
  private name = "DemoAccountDomainExists";

  get showDomainExists(): boolean {
    return this.$store.getters["demoAccount/showDomainExists"];
  }

  /**
   * Computes the max width for the dialog depending on the targeted breakpoint.
   */
  get maxWidth(): string {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
        return "100vw";
      default:
        return "30vw";
    }
  }

  /**
   * Reset checkbox and close the dialog
   */
  close(): void {
    this.$store.commit("demoAccount/setShowDomainExists", false);
  }

  register(): void {
    this.$store.commit("registration/setDemoAccount", null);
    this.$router.push({ name: "registration-form" });
    this.close();
  }
}
