import {
  OrgUnit,
  RegistrationFlow,
  RegistrationForm,
  RegistrationStepEnum
} from "@/types";
import { RegistrationState } from "./state";

export interface RegistrationGetters {
  form(state: RegistrationState): RegistrationForm;
  showPolicy(state: RegistrationState): boolean;
  routeNames(state: RegistrationState): object;
  step(state: RegistrationState): RegistrationStepEnum;
  registrationFlow(state: RegistrationState): RegistrationFlow | undefined;
  orgUnits(state: RegistrationState): Array<OrgUnit> | undefined;
  demoAccount(state: RegistrationState): string | undefined;
}

export const getters: RegistrationGetters = {
  form(state: RegistrationState): RegistrationForm {
    return state.form;
  },
  showPolicy(state: RegistrationState): boolean {
    return state.showPolicy;
  },
  routeNames(state: RegistrationState) {
    return state.routeNames;
  },
  step(state: RegistrationState): RegistrationStepEnum {
    return state.step;
  },
  registrationFlow(state: RegistrationState): RegistrationFlow | undefined {
    return state.registrationFlow;
  },
  orgUnits(state: RegistrationState): Array<OrgUnit> | undefined {
    return state.orgUnits;
  },
  demoAccount(state: RegistrationState): string | undefined {
    return state.demoAccount;
  }
};
