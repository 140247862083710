/* eslint-disable @typescript-eslint/no-explicit-any */
import store from "@/store";
import { GlobalConfig, StatusType } from "@/types";
import Register from "@/views/Register.vue";
import RegisterView from "@/views/registration/RegisterForm.vue";
import Confirmation from "@/views/registration/Confirmation.vue";
import ConfirmOptIn from "@/views/ConfirmOptIn.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ConfirmLogout from "@/views/ConfirmLogout.vue";
import DataTakeout from "@/views/DataTakeout.vue";
import Page404 from "@/views/Page404.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import { RouteConfig } from "vue-router";
import Policy from "@/views/Policy.vue";
import registrationFlow from "../configs/registrationFlow";

const globalConfig: GlobalConfig = registrationFlow.globalConfig;
const demoAccountRoutes: Array<RouteConfig> = Object.keys(
  globalConfig.demoAccounts
).map((path) => {
  return {
    path: path,
    component: RegisterView
  };
});

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Register,
    children: [
      {
        name: "registration-form",
        path: "/",
        component: RegisterView
      },
      ...demoAccountRoutes,
      //
      // please note that the name of this step has to be "registration-confirmation"
      // as it is referenced in RegisterForm.vue when setting the route after
      // successfully completing the registration.
      // It is a mandatory route for every customer!
      //
      {
        name: "registration-confirmation",
        path: "confirmation",
        component: Confirmation,
        beforeEnter: (_to: any, _from: any, next: any) => {
          // if direct access, then go to registration form
          if (store.getters["app/status"] === StatusType.registrationSuccessful)
            next();
          else next({ name: "registration-form" });
        }
      }
    ]
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Policy
  },
  {
    path: "/optin/confirmation/:optinID?",
    name: "optin-confirmation",
    component: ConfirmOptIn
  },
  {
    path: "/forgotPassword",
    name: "forgot-password",
    component: ForgotPassword
  },
  {
    path: "/resetPassword/:passwordResetToken",
    name: "reset-password",
    component: ResetPassword
  },
  {
    path: "/confirmLogout",
    name: "confirm-logout",
    component: ConfirmLogout
  },
  {
    path: "/dataTakeout/:otp",
    name: "data-takeout",
    component: DataTakeout
  },
  {
    path: "*",
    name: "404",
    component: Page404
  }
];

export default routes;
