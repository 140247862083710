import { MutationTree } from "vuex";
import { DataTakeoutState } from "./state";

export interface DataTakeoutMutations {
  setOtp(state: DataTakeoutState, otp: string): void;
}

export const mutations: MutationTree<DataTakeoutState> = {
  setOtp(state: DataTakeoutState, otp: string): void {
    state.otp = otp;
  }
};
