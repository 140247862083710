
import { Component, Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";
import LmCard from "@/components/LMCard.vue";

@Component({
  components: {
    LmCard
  }
})
export default class ConfirmationPage extends Mixins(Config) {
  name = "ConfirmationPage";

  title = this.$t("confirmation.title");
  toLogin(): void {
    window.location.replace(process.env.VUE_APP_PRIVATE_APP_URL || "#");
  }
}
