import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

// function is used to resolve file during run time and avoid compiler error "process not defined"
function theme() {
  const {
    light
    // eslint-disable-next-line @typescript-eslint/no-var-requires
  } = require(`../customers/${
    process.env.VUE_APP_CUSTOMER || "default"
  }/theme/theme.ts`);
  return light;
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: theme()
    }
  }
});
