
import TextField from "@/components/TextField.vue";
import i18n from "@/i18n";
import Vue from "vue";
import Component from "vue-class-component";
import VueI18n from "vue-i18n";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    TextField
  }
})
export default class ForgotPasswordInput extends Vue {
  @Prop({ default: "" })
  private value!: string;

  @Prop({ default: false })
  plainInput!: boolean;

  @Prop({ default: false })
  submitValidationTrigger!: boolean;

  @Prop({ default: "" })
  backendError!: string;

  @Prop({ default: "" })
  rules!: true | string | VueI18n.TranslateResult;

  public username = this.value || "";

  public valid = false;

  public backendRules: Array<VueI18n.TranslateResult> = [];

  handleValidChange(newValue: boolean) {
    this.valid = newValue;
    if (newValue && this.backendRules.length > 0) {
      this.backendRules.pop();
    }
    this.$emit("update:valid", newValue);
  }

  // parent components can react to changes
  @Watch("username")
  onEmailAddressChanged(newValue: string) {
    this.$emit("input", newValue);
  }

  @Watch("backendError")
  onBackendErrorChanged(newValue: string) {
    if (newValue) {
      this.backendRules.push(i18n.t(newValue));
    }
  }

  handleFocus() {
    this.$emit("focus");
  }
}
