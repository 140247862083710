import { DataTakeoutState } from "./state";

export interface DataTakeoutPwdGetters {
  otp(state: DataTakeoutState): null | string;
}

export const getters: DataTakeoutPwdGetters = {
  otp(state: DataTakeoutState): null | string {
    return state.otp;
  }
};
