
import LmCard from "@/components/LMCard.vue";
import CenteredContent from "@/components/CenteredContent.vue";
import { StatusType } from "@/types";
import { AxiosResponse } from "axios";
import { Component, Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";
@Component({ components: { LmCard, CenteredContent } })
export default class DataTakeout extends Mixins(Config) {
  name = "DataTakeout";
  title = this.$t("data-takeout.title");
  get loading() {
    return (
      this.$store.getters["app/status"] === StatusType.downloadingDataTakeout
    );
  }
  async created() {
    this.$store.commit("dataTakeout/setOtp", this.$route.params.otp);
    this.download();
  }
  message = { text: this.$t("data-takeout.status.starting") };
  download() {
    this.$store
      .dispatch("dataTakeout/dl")
      .then((response) => {
        this.message.text = this.$t("data-takeout.status.done");
        this.saveFile(response);
      })
      .catch(() => {
        this.message.text = this.$t("data-takeout.status.error", {
          url: `${process.env.VUE_APP_PRIVATE_APP_URL}/privacy` || "#"
        });
      });
  }
  saveFile = (response: AxiosResponse) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.data.type })
    );
    const contentDisposition = response.headers["content-disposition"];
    let fileName = "unknown";
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=(.+)/);
      if (fileNameMatch != null && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
}
