
import Config from "@/mixins/config";
import { Component, Mixins } from "vue-property-decorator";
import de from "@/components/policy/PolicyDe.vue";
import en from "@/components/policy/PolicyEn.vue";

/*
  This is the view for the policy display via route /policy
  The actual policy information is customer specific and can be found in 
  <customer folder>/components/policy/Policy<lang>.vue.
  The policy dialogue overlay can be found in src/components/registration/steps/PolicyDialog.vue
*/
@Component
export default class PolicyView extends Mixins(Config) {
  name = "PolicyView";

  title = this.$t("policy.title");

  de: any = null;
  en: any = null;

  async created() {
    this.de = await this.getComponent("policy/PolicyDe");
    this.en = await this.getComponent("policy/PolicyEn");
  }
}
