
import CenteredContent from "@/components/CenteredContent.vue";
import LmCard from "@/components/LMCard.vue";
import { focus } from "@/directives/focus";
import Config from "@/mixins/config";
import { HttpService } from "@/services/HttpService";
import { StatusType } from "@/types";
import { Component, Mixins } from "vue-property-decorator";
import VueI18n from "vue-i18n";

@Component({ components: { LmCard, CenteredContent }, directives: { focus } })
export default class ConfirmOptIn extends Mixins(Config) {
  name = "ConfirmOptIn";
  validating = this.$t("optin.validating");
  success = this.$t("optin.success");
  isValidating = true;
  public optinID: string | null = null;

  get loading() {
    return this.$store.getters["app/status"] === StatusType.checkingOptIn;
  }
  mounted() {
    this.isValidating = true;
    this.optinID = this.$route.params.optinID;
    this.validateOptin();
  }
  validateOptin() {
    if (this.optinID) {
      this.$store.commit("app/setStatus", StatusType.checkingOptIn);
      HttpService.post("/persons/optins/" + this.optinID)
        .then(() => {
          this.isValidating = false;
        })
        .catch((error) => {
          if (error.response) {
            this.validating = this.$t("optin.error");
            this.$store.commit("app/setStatus", null);
          } else {
            this.$store.commit("app/setStatus", StatusType.error);
          }
        });
    }
  }
  toLogin(): void {
    window.location.replace(
      (process.env.VUE_APP_PORTAL_APP || "#") + "?origin=optInConfirmation"
    );
  }
}
