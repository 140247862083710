import i18n from "@/i18n";
import { HttpService } from "@/services/HttpService";
import { StatusType } from "@/types";
import { ActionContext } from "vuex";
import { StateInterface } from "../state";
import { ResetPwdState } from "./state";
import { AxiosRequestConfig } from "axios";

export interface ResetPwdActions {
  checkOtp(
    context: ActionContext<ResetPwdState, StateInterface>
  ): Promise<unknown>;
  resetPassword(
    context: ActionContext<ResetPwdState, StateInterface>
  ): Promise<unknown>;
}
export const actions: ResetPwdActions = {
  checkOtp({ state, commit }) {
    commit("app/setStatus", StatusType.checkingOtpResetPassword, {
      root: true
    });

    return new Promise((resolve, reject) => {
      HttpService.get(`/persons/resetpassword/${state.otp}`, {
        validateStatus: (status: number) => {
          return status === 204;
        }
      })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() =>
          commit("app/setStatus", null, {
            root: true
          })
        );
    });
  },
  resetPassword(
    context: ActionContext<ResetPwdState, StateInterface>
  ): Promise<unknown> {
    context.commit("app/setStatus", StatusType.resettingPassword, {
      root: true
    });

    return new Promise((resolve, reject) => {
      HttpService.post(
        "/persons/resetpassword",
        {
          passwordResetToken: context.state.otp,
          newPassword: context.state.password
        },
        {
          validateStatus: (status) => {
            return status === 200;
          }
        } as AxiosRequestConfig<any>
      )
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          let reason = "reset-password.error.unexpected";
          if (error.response) {
            if (error.response.status === 404) {
              reason = "reset-password.error.unknown";
            }
          }
          reject(i18n.t(reason));
        })
        .finally(() =>
          context.commit("app/setStatus", null, {
            root: true
          })
        );
    });
  }
};
