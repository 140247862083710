
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class PreferredLanguageSelect extends Vue {
  @Prop({ default: "" })
  modelValue!: string;
  localModel = this.modelValue ?? "de";

  get languages(): Array<{ text: string; value: string }> {
    const languages: Array<{ text: string; value: string }> = [];
    languages.push({ text: "Deutsch", value: "de" });
    return languages;
  }

  @Watch("localModel")
  onLocalModelChanged(newValue: { text: string; value: string }) {
    this.$emit("input", newValue.value);
  }
}
