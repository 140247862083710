
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class YearSelect extends Vue {
  @Prop({ default: "" })
  modelValue!: string;
  localModel = this.modelValue ?? "";

  get years(): Array<number> {
    const max = new Date().getFullYear();
    const min = 1900;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  @Watch("localModel")
  onLocalModelChanged(newValue: number) {
    this.$emit("input", newValue);
  }
}
