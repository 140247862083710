
import { OrgUnit } from "@/types";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class OrgUnitSelect extends Vue {
  @Prop({ default: "" })
  value!: string;

  @Prop({ default: [] })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules!: Array<any>;

  localModel = this.value ?? "";

  valid = false;

  orgUnitRules =
    this.rules ??
    ([
      (value: string) => !!value || this.$t("register.orgUnits.error")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as Array<any>);

  get orgUnits(): Array<{ value: string; text: string }> {
    const orgUnitsFromState = this.$store.getters["registration/orgUnits"];

    const values: Array<{ value: string; text: string }> = [];
    orgUnitsFromState.forEach((ou: OrgUnit) => {
      values.push({
        value: ou.id,
        text: ou.name
      });
    });
    return values;
  }
  handleSelect() {
    this.valid = true;
    this.$emit("update:valid", true);
  }
  @Watch("localModel")
  onValueChanged(newValue: string) {
    this.$emit("input", newValue);
  }
}
