
import Config from "@/mixins/config";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class InviteeExists extends Mixins(Config) {
  name = "InviteeExists";

  title = this.$t("inviteeExists.title");
}
