import { Status } from "@/types";

export interface AppState {
  status: Status;
}

export const defaultState = (): AppState => {
  return {
    status: null
  };
};
