import { RegistrationState } from "./store/registration/state";

export interface RegistrationForm {
  // LoginMaster
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  otp?: string;
  // ias
  employeeAssignment?: null | boolean;
  yearOfBirth?: null | number;
  gender?: null | string;
  preferredLanguage?: string;
  newsletterConsent?: null | boolean;
  orgUnit?: null | string;
  workAtDesk?: null | boolean;
  mobileNumber?: string;
}

export type VForm = Vue & { validate: () => void; reset: () => void };

export enum StatusType {
  checkingEmail,
  creatingAccount,
  registrationSuccessful,
  error,
  checkingOptIn,
  sendingResetPwdReq,
  resetPwdReqSent,
  checkingOtpResetPassword,
  resettingPassword,
  downloadingDataTakeout,
  gettingInvitation,
  loadingOrgUnit
}

export type Status = null | StatusType;

export interface Invitation {
  mailAddress: string;
  orgUnitId: string;
  orgUnitName: string;
  senderDisplayName: string;
}

export interface OrgUnit {
  id: string;
  name: string;
}

/**
 * Generic configuration for a single field in a component config
 */
export type ComponentFieldConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
  /**
   * enabled = true means that the component is displayed
   */
  enabled: boolean;
};

/**
 * Generic description of a component config
 */
export type ComponentConfig =
  | ({
      [k: string]: ComponentFieldConfig;
    } & {
      component: string;
    })
  | {
      component: string;
    };

/**
 * List of all possible steps for all customers combined!
 *
 * The steps that are valid for a specific customer are defined in the customer
 * folder unter configs/registrationFlow.ts.
 *
 * We use string-valued enum values to be able to use them in the RegistrationFlow
 * type as index. This is not possible with non-valued/numeric-valued enum values.
 */
export enum RegistrationStepEnum {
  BASIC_INFO = "basicinfo",
  CHOOSE_ORG_UNIT = "chooseorgunit",
  OTHER_INFO = "otherinfo",
  POLICY = "policy"
}

/**
 * RegistrationFlow describes the format of the definition what steps should
 * be performed in a customer specific registration process. It defines the
 * components to be displayed step by step. The index of each step is of type
 * RegistrationStepEnum.
 */
export type RegistrationFlowStep = {
  /**
   * unique name of the flow step; for readability, only
   */
  name: string;
  /**
   * component config of components within the component
   */
  config: ComponentConfig;
  /**
   * This function returns true if the condition is in the function is met, else otherwise.
   * The result of this call is used to decide whether the step is skipped
   * (preCondition returns false) or not.
   * @param {RegistrationState} regState - current registration state
   * @returns {boolean} true if implemented condition is met.
   */
  preCondition?: (regState: RegistrationState) => boolean;
  /**
   * previous step contains the index of the previous step. If it is null, there
   * is no previous step and this is the first step.
   */
  previousStep: null | RegistrationStepEnum;
  /**
   * next step contains the index of the next step. If it is null, there
   * is no next step and this is the last step
   */
  nextStep: null | RegistrationStepEnum;
};

/**
 * a flow is an array of RegistrationFlowStep s but with the index of the
 * RegistrationStepEnum type
 */
export type RegistrationFlow = {
  [key in RegistrationStepEnum]?: RegistrationFlowStep;
} & {
  [k: string]: GlobalConfig;
};

export type GlobalConfig = {
  [k: string]: any;
};
