
import { Component, Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";

@Component
export default class Page404 extends Mixins(Config) {
  name = "Page404";

  title = this.$t("404.title");
}
