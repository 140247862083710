
import Config from "@/mixins/config";
import ErrorPage from "./components/ErrorPage.vue";
import { StatusType } from "./types";
import { Component, Mixins } from "vue-property-decorator";

@Component({ components: { ErrorPage } })
export default class App extends Mixins(Config) {
  name = "App";

  CustomHeader: any = null;
  CustomFooter: any = null;

  get errorInStore(): boolean {
    return this.$store.getters["app/status"] === StatusType.error;
  }

  async created() {
    this.CustomHeader = await this.getComponent("header");
    this.CustomFooter = await this.getComponent("footer");
  }
}
