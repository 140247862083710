import { StateInterface } from "@/store/state";
import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { defaultState, ForgotPwdState } from "./state";

export const ForgotPwdModule: Module<ForgotPwdState, StateInterface> = {
  namespaced: true,
  actions: {
    ...actions
  },
  getters: {
    ...getters
  },
  state: {
    ...defaultState()
  },
  mutations: {
    ...mutations
  }
};
