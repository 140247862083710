
import { Component, Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";

@Component
export default class ErrorPage extends Mixins(Config) {
  name = "ErrorPage";

  title = this.$t("errorPage.title");
}
