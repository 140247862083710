
import TextField from "@/components/TextField.vue";
import i18n from "@/i18n";
import Config from "@/mixins/config";
import Component from "vue-class-component";
import VueI18n from "vue-i18n";
import { Mixins, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    TextField
  }
})
export default class EmailAddressInput extends Mixins(Config) {
  @Prop({ default: "" })
  private value!: string;

  @Prop({ default: false })
  enabled!: boolean;

  @Prop({ default: false })
  plainInput!: boolean;

  @Prop({ default: false })
  submitValidationTrigger!: boolean;

  @Prop({ default: "" })
  backendError!: string;

  get emailRules() {
    return [(value: string) => !!value || this.$t("email.error.missing")];
  }

  public rules: Array<
    (value: string) => true | string | VueI18n.TranslateResult
  > = this.emailRules;

  public emailAddress = this.value || "";

  public valid = false;

  public backendRules: Array<VueI18n.TranslateResult> = [];

  handleValidChange(newValue: boolean) {
    this.valid = newValue;
    if (newValue && this.backendRules.length > 0) {
      this.backendRules.pop();
    }
    this.$emit("update:valid", newValue);
  }

  // parent components can react to changes
  @Watch("emailAddress")
  onEmailAddressChanged(newValue: string) {
    this.$emit("input", newValue);
  }

  @Watch("backendError")
  onBackendErrorChanged(newValue: string) {
    if (newValue) {
      this.backendRules.push(i18n.t(newValue));
    }
  }

  handleFocus() {
    this.$emit("focus");
  }

  async created() {
    const globalConfig = this.getGlobalConfig();
    this.rules.push(
      (value: string) =>
        globalConfig.inputFields.emailAddress.regex.test(value) ||
        this.$t("email.error.wrong")
    );
  }
}
