export interface ResetPwdState {
  otp: null | string;
  password?: string;
}

export const defaultState = (): ResetPwdState => {
  return {
    otp: null
  };
};
