
import LmCard from "@/components/LMCard.vue";
import CenteredContent from "@/components/CenteredContent.vue";
import { Component, Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";

@Component({
  components: { LmCard, CenteredContent }
})
export default class ConfirmLogout extends Mixins(Config) {
  name = "ConfirmLogout";

  title = this.$t("logout.title");

  privateSiteLink = process.env.VUE_APP_PRIVATE_APP_URL || null;
  portalLink = process.env.VUE_APP_PORTAL_APP || null;
}
