
import LmCard from "@/components/LMCard.vue";
import CenteredContent from "@/components/CenteredContent.vue";
import { GlobalConfig, StatusType, VForm } from "@/types";
import { Component, Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";
import { focus } from "@/directives/focus";

@Component({
  components: { LmCard, CenteredContent },
  directives: { focus }
})
export default class ResetPassword extends Mixins(Config) {
  name = "ResetPassword";

  title = this.$t("reset-password.title");

  ready = false;
  validForm = true;
  otpValid: boolean | null = null;

  passwordsValid = false;

  // Load password input component in created()
  PasswordInputs = null;

  password = "";

  globalConfig: GlobalConfig = {
    inputFields: {
      passwords: {
        enabled: true,
        plainInput: false
      }
    }
  };

  async created() {
    // Load password input component
    this.PasswordInputs = await this.getComponent("PasswordInputs");

    const regFlow = await this.getRegistrationFlow();
    this.$store.commit("registration/setRegistrationFlow", regFlow);

    this.globalConfig = this.getGlobalConfig();
    this.$store.commit(
      "resetPassword/setOtp",
      this.$route.params.passwordResetToken
    );
    try {
      await this.$store.dispatch("resetPassword/checkOtp");
      this.otpValid = true;
    } catch (error) {
      this.otpValid = false;
    }
  }

  get passwordsConfig() {
    return this.globalConfig?.inputFields?.passwords;
  }

  get loading() {
    return [
      StatusType.resettingPassword,
      StatusType.checkingOtpResetPassword
    ].includes(this.$store.getters["app/status"]);
  }

  get otp(): undefined | string {
    return this.$store.getters["resetPassword/otp"];
  }

  async validateForm() {
    if (this.$refs?.passwords && (this.$refs?.passwords as any).validate) {
      (this.$refs.passwords as any).validate();
    }
    (this.$refs.form as VForm).validate();

    await this.$nextTick();
    this.resetPassword();
  }

  handleValidChange(newValidValue: boolean) {
    this.passwordsValid = newValidValue;
  }

  resetPassword() {
    if (this.validForm && this.passwordsValid) {
      this.$store.commit("resetPassword/setNewPassword", this.password);

      this.$store
        .dispatch("resetPassword/resetPassword")
        .then(() => this.$store.commit("resetPassword/setOtp", null))
        .catch((error) => {
          // push and pop are implementented in PasswordInputs
          (this.$refs.passwords as any).pushRule(() => error);
          (this.$refs.form as VForm).validate();
        });
    }
  }
}
