
import { StatusType, RegistrationStepEnum } from "@/types";
import { Component, Mixins } from "vue-property-decorator";
import Policy from "@/views/Policy.vue";
import Config from "@/mixins/config";
/**
 * Popup view for displaying a userId and its linked systems
 *
 * It is on top of every Registration sub-view
 */
@Component({
  components: {
    Policy
  }
})
export default class PolicyDialog extends Mixins(Config) {
  private name = "PolicyDialog";

  conditionOfUseLink = (this.$t("links.conditionOfUse") ?? "") as string;
  policyLink = (this.$t("links.policy") ?? "") as string;

  consentGiven = false;
  newsletterConsentGiven = false;
  form = {};
  showPolicy = true;

  get showNewsletterConsent() {
    return (this.getFormConfig(RegistrationStepEnum.POLICY) || {}).newsletter;
  }

  /**
   * Computes the max width for the dialog depending on the targeted breakpoint.
   */
  get maxWidth(): string {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
        return "100vw";
      default:
        return "70vw";
    }
  }

  get submitting(): boolean {
    return this.$store.getters["app/status"] === StatusType.creatingAccount;
  }

  /**
   * Reset checkbox and close the dialog
   */
  async close(previous = true): Promise<void> {
    this.consentGiven = false;
    this.newsletterConsentGiven = false;
    this.showPolicy = false;
    this.$store.commit("app/setStatus", null);
    if (previous) await this.$store.dispatch("registration/previousStep");
  }
  /**
   * Check if checkbox has been checked and submit data
   */
  async register(): Promise<void> {
    if (this.consentGiven) {
      this.form = {
        ...this.$store.getters["registration/form"],
        newsletterConsent: this.newsletterConsentGiven
      };
      this.$store.commit("registration/setForm", this.form);
      //
      // registration/register returns Promise<true | any>
      // true: registration successful
      // any: registration failed, result contains error code
      //
      const registerResult = await this.$store.dispatch(
        "registration/register"
      );
      await this.close(false);
      if (registerResult === true) {
        // if successfull ...
        this.$store.commit("app/setStatus", StatusType.registrationSuccessful);
        // go to next step
        await this.$store.dispatch("registration/nextStep");
      } else {
        this.$store.commit("app/setStatus", StatusType.error);
      }
    }
  }
  toggleConsent(): void {
    this.consentGiven = !this.consentGiven;
  }
  toggleNewsletterConsent(): void {
    this.newsletterConsentGiven = !this.newsletterConsentGiven;
  }
}
