/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const languages = ["en", "de"];
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";

let startingLocale =
  navigator.language.slice(0, 2) || process.env.VUE_APP_I18N_LOCALE || "en";

startingLocale = languages.includes(startingLocale)
  ? startingLocale
  : fallbackLocale;

const i18n = new VueI18n({
  locale: startingLocale,
  fallbackLocale: fallbackLocale,
  messages: {}
});

const loadedLanguages: Array<string> = [];

function setI18nLanguage(locale: string) {
  i18n.locale = locale;
  axios.defaults.headers.common["Accept-Language"] = locale;
  const html = document.querySelector("html");
  if (html) html.setAttribute("lang", locale);
  return locale;
}

/*
  the message objects have a tree structure and can't be merged with the
  standard spread operator ... => this function merges the two objects 
  obj1 and obj2. The props of obj1 have a higher priority than the props
  in obj2.
*/
function combineKeys(obj1: any, obj2: any) {
  const merged = {};

  // Function to merge keys from both objects
  function mergeKeys(target: any, source: any) {
    for (const key in source) {
      if (source[key]) {
        if (target[key]) {
          if (
            typeof target[key] === "object" &&
            typeof source[key] === "object"
          ) {
            // Recursive call if both values are objects
            target[key] = mergeKeys(target[key], source[key]);
          }
        } else {
          // Add new key if it doesn't exist in target object
          // i.e. keep the key if it already exists
          target[key] = source[key];
        }
      }
    }

    return target;
  }

  // Merge keys from both objects
  mergeKeys(merged, obj1);
  mergeKeys(merged, obj2);

  return merged;
}

export function loadLocaleMessagesAsync(locale: string) {
  if (loadedLanguages.length > 0 && i18n.locale === locale) {
    return Promise.resolve(setI18nLanguage(locale));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    return Promise.resolve(setI18nLanguage(locale));
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
  ).then(async (messages) => {
    // load project wide messages
    const defaultMessages = messages.default;
    // load messages of a customer if existing
    let customerSpecificMessages = {};
    // if message file for a customer is not existing, skip this step
    try {
      customerSpecificMessages = await import(
        `@/customers/${
          process.env.VUE_APP_CUSTOMER || "default"
        }/locales/${locale}.json`
      );
    } catch (_reason: unknown) {
      // do nothing
    }

    // combine both messages while customer messages
    // have higher priority
    const combinedMessages = combineKeys(
      customerSpecificMessages,
      defaultMessages
    );
    // set messages for project
    i18n.setLocaleMessage(locale, combinedMessages);
    loadedLanguages.push(locale);
    return setI18nLanguage(locale);
  });
}

export default i18n;
