// declare the v-focus directive that will give the focus to an input or a textarea
export const focus = {
  inserted(el: HTMLElement) {
    if (el) {
      const input = el.querySelector(
        "input:not([type=hidden]),textarea:not([type=hidden])"
      ) as HTMLElement;
      input.focus();
    }
  }
};
