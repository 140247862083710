import { DemoAccountState } from "./state";

export interface DemoAccountGetters {
  showDomainExists(state: DemoAccountState): boolean;
}

export const getters: DemoAccountGetters = {
  showDomainExists(state: DemoAccountState): boolean {
    return state.showDomainExists;
  }
};
