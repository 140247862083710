import defaultRoutes from "../customers/default/routes/routes";
import iasRoutes from "../customers/ias/routes/routes";

// load the customer specific routes from customer folder
export const loadCustomerRoutes = (customerName: string) => {
  try {
    switch (customerName) {
      case "ias":
        return iasRoutes;
      case "default":
      default:
        return defaultRoutes;
    }
  } catch (error) {
    console.error(
      `Error during dynamic route generation for customer ${customerName}. `,
      error
    );
  }
};
