
import GenderSelect from "@/components/registration/GenderSelect.vue";
import PreferredLanguageSelect from "@/components/registration/PreferredLanguageSelect.vue";
import YearSelect from "@/components/registration/YearSelect.vue";
import {
  RegistrationForm,
  RegistrationStepEnum,
  StatusType,
  VForm
} from "@/types";
import Vue from "vue";
import Component from "vue-class-component";
import FormContainer from "@/components/registration/FormContainer.vue";
import Config from "@/mixins/config";
import { Mixins } from "vue-property-decorator";
import { OtherInfoConfig } from "./OtherInfo.config";

@Component({
  components: {
    YearSelect,
    GenderSelect,
    PreferredLanguageSelect,
    FormContainer
  },
  directives: {
    focus
  }
})
export default class OtherInfo extends Mixins(Config) {
  name = "RegisterForm-OtherInfo";

  form: RegistrationForm = {
    ...this.$store.getters["registration/form"]
  };

  formConfig: OtherInfoConfig = {
    component: "ChooseOrgUnit",
    gender: { enabled: true },
    preferredLanguage: { enabled: true },
    yearOfBirth: { enabled: true },
    employeeAssignment: { enabled: true },
    workAtDesk: { enabled: true }
  };

  // placeholder for the rules used to validate the form on submission
  rules = {
    employeeAssignmentRules: [
      (value: boolean) =>
        value != null || this.$t("register.employeeAssignment.error")
    ],
    workAtDeskRules: [
      (value: boolean) => value !== null || this.$t("register.workAtDesk.error")
    ]
  };
  // status of the form
  valid = true;

  get loading(): boolean {
    return this.$store.getters["app/status"] === StatusType.checkingEmail;
  }

  /**
   * The following getters help to prevent nasty warnings of the parser
   * during Unit tests.
   */

  get yearOfBirthEnabled(): boolean {
    return this.formConfig.yearOfBirth?.enabled;
  }

  get genderEnabled(): boolean {
    return this.formConfig.gender?.enabled;
  }

  get prefLangEnabled(): boolean {
    return this.formConfig.preferredLanguage?.enabled;
  }

  get workatdeskEnabled(): boolean {
    return this.formConfig.workAtDesk?.enabled;
  }

  get emplAssigEnabled(): boolean {
    return this.formConfig.employeeAssignment?.enabled;
  }

  async validateForm() {
    (this.$refs.form as VForm).validate();

    await Vue.nextTick();
    if (this.valid) {
      this.$store.commit("registration/setForm", this.form);
      this.$store.commit("app/setStatus", null);
      await this.$store.dispatch("registration/nextStep");
    }
  }

  // Load config
  async created() {
    this.formConfig = this.getFormConfig(RegistrationStepEnum.OTHER_INFO);
  }

  async previousStep(): Promise<void> {
    this.$store.commit("registration/setForm", this.form);
    this.$store.commit("app/setStatus", null);
    await this.$store.dispatch("registration/previousStep");
  }
}
