
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class GenderSelect extends Vue {
  @Prop({ default: "" })
  modelValue!: string;

  localModel = this.modelValue ?? "";

  get genders(): Array<{ text: string; value: string }> {
    const genders: Array<{ text: string; value: string }> = [];
    ["m", "f", "o"].forEach((k) => {
      genders.push({
        text: this.$t(`register.gender.${k}`) as string,
        value: k
      });
    });
    return genders;
  }

  @Watch("localModel")
  onLocalModelChanged(newValue: { text: string; value: string }) {
    this.$emit("input", newValue.value);
  }
}
