import i18n from "@/i18n";
import { HttpService } from "@/services/HttpService";
import { StatusType } from "@/types";
import { ActionContext } from "vuex";
import { StateInterface } from "../state";
import { ForgotPwdState } from "./state";
import { AxiosRequestConfig } from "axios";

export interface ForgotPwdActions {
  sendResetPwdReq(
    context: ActionContext<ForgotPwdState, StateInterface>
  ): Promise<unknown>;
}
export const actions: ForgotPwdActions = {
  sendResetPwdReq(
    context: ActionContext<ForgotPwdState, StateInterface>
  ): Promise<unknown> {
    context.commit("app/setStatus", StatusType.sendingResetPwdReq, {
      root: true
    });

    return new Promise((resolve, reject) => {
      HttpService.put(
        "/persons/sendpasswordresettoken",
        {
          mailAddress: context.state.emailAddress,
          mobileNumber: context.state.mobileNumber
        },
        {
          validateStatus: (status) => {
            return status === 200;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as AxiosRequestConfig<any>
      )
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          let reason = "forgot-password.error.unexpected";
          if (error.response) {
            if (error.response.status === 404) {
              reason = `forgot-password.error.${
                context.state.emailAddress ? "mailAddress" : "mobileNumber"
              }.unknown`;
            } else if (error.response.status === 422) {
              reason = `forgot-password.error.${
                context.state.emailAddress ? "mailAddress" : "mobileNumber"
              }.activation`;
            }
          }
          reject(reason);
        })
        .finally(() =>
          context.commit("app/setStatus", null, {
            root: true
          })
        );
    });
  }
};
