import axios, { AxiosInstance, CreateAxiosDefaults } from "axios";

/**
 * Preconfigured AxiosInstance with access to vuex store.
 */
export type HttpServiceInterface = AxiosInstance;

export let HttpService: HttpServiceInterface;

/**
 * Should be first called to initialize axios configuration for the rest of the application.
 */
export const initHttpService = () => {
  HttpService = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL as string,
    headers: {
      "Content-Type": "application/json"
    }
  } as CreateAxiosDefaults<any>);
};
