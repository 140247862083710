
import Config from "@/mixins/config";
import Component from "vue-class-component";
import { Mixins, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TextField extends Mixins(Config) {
  @Prop({ default: "" })
  modelValue!: string;

  @Prop({ default: "" })
  label!: string;

  @Prop({ default: "" })
  name!: string;

  @Prop({ default: "text" })
  type!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: () => [] })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules!: Array<any>;

  @Prop({ default: () => [] })
  backendRules!: Array<any>;

  @Prop({ default: false })
  persistentHint!: boolean;

  @Prop({ default: false })
  enabled!: boolean;

  @Prop({ default: "" })
  hint!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  plainInput!: boolean;

  @Prop({ default: false })
  submitValidationTrigger!: boolean;

  @Prop({ default: false })
  error!: boolean;

  @Prop({ default: "" })
  errorMessages!: string;

  valid = false;

  style = "";

  inputErrorMessage = "";

  // do not modify models passed by props directly as they are
  // overwritten if the parent re-renders
  localModel = this.modelValue ?? "";

  get classes() {
    return `form-control ${this.required ? "required" : ""} ${
      !this.enabled ? "visHidden" : ""
    }`;
  }

  get combinedRules() {
    return [...this.rules, ...this.backendRules];
  }

  checkForError() {
    this.inputErrorMessage = "";
    this.rules.forEach((rule) => {
      if (this.inputErrorMessage) {
        return;
      }
      const ruleConclusion = rule(this.localModel);
      if (typeof ruleConclusion === "string") {
        this.inputErrorMessage = ruleConclusion;
      }
    });
    if (!this.inputErrorMessage) {
      this.backendRules.forEach((error) => {
        this.inputErrorMessage = error;
      });
    }
  }

  // if local model is modifified, emit information to parent model
  @Watch("localModel")
  onLocalModelChanged(newValue: string) {
    this.valid = this.rules.every(
      (rule) => typeof rule(newValue) === "boolean"
    );
    this.$emit("input", newValue);
  }

  @Watch("backendRules")
  onBackendRulesChanged() {
    this.valid = false;
    this.checkForError();
  }

  @Watch("valid")
  onValidChanged(newValid: any) {
    this.$emit("update:valid", newValid);
  }

  @Watch("enabled")
  onEnabledChanged() {
    this.style = !(this.enabled ?? false) ? "visibility: hidden;" : "";
  }

  @Watch("submitValidationTrigger")
  onSubmitValidationTriggerChanged() {
    this.checkForError();
  }

  handleFocus() {
    this.$emit("focus");
  }

  handleBlur() {
    this.checkForError();
  }
}
