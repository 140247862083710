
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * v-card with a green border and a gray background
 */
@Component
export default class LMCard extends Vue {
  name = "LMCard";

  @Prop({ default: false })
  loading!: boolean;
}
