import Vue from "vue";
import App from "./App.vue";
import i18n, { loadLocaleMessagesAsync } from "./i18n";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import { initHttpService } from "./services/HttpService";
import store from "./store";

Vue.config.devtools = false;
Vue.config.productionTip = process.env.NODE_ENV === "production";

initHttpService();

loadLocaleMessagesAsync(i18n.locale).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App)
  }).$mount("#app");
});
