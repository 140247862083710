
import Vue from "vue";
import EmailAddressInput from "@/components/EmailAddressInput.vue";
import TextField from "@/components/TextField.vue";
import { focus } from "@/directives/focus";
import { RegistrationForm, StatusType, VForm, Invitation } from "@/types";
import { Component, Mixins } from "vue-property-decorator";
import FormContainer from "@/components/registration/FormContainer.vue";
import Config from "@/mixins/config";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    EmailAddressInput,
    TextField,
    FormContainer
  },
  directives: {
    focus
  }
})
export default class BasicInfo extends Mixins(Config) {
  name = "RegisterForm-BasicInfo";

  @Prop({
    default: {
      mailAddress: "",
      orgUnitId: "",
      orgUnitName: "",
      senderDisplayName: ""
    }
  })
  invitation!: Invitation;

  form: RegistrationForm = {
    ...this.$store.getters["registration/form"]
  };

  // passwordInfos component
  PasswordInputs: any = null;
  BasicInfoFunctions: any = null;

  // placeholder for the rules used to validate the form on submission
  rules = {
    firstNameRules: [
      (value: string) => !!value || this.$t("register.firstName.error")
    ],
    lastNameRules: [
      (value: string) => !!value || this.$t("register.lastName.error")
    ]
  };

  backendError = "";

  // status of the form
  valid = true;

  get loading(): boolean {
    return (
      this.$store.getters["app/status"] === StatusType.checkingEmail ||
      this.$store.getters["app/status"] === StatusType.loadingOrgUnit
    );
  }

  // Load password input component
  async created() {
    this.PasswordInputs = await this.getComponent("PasswordInputs");
    this.BasicInfoFunctions = await this.getFunctions("BasicInfoFunctions");
  }

  async validateForm() {
    if (this.$refs?.emailAddres) {
      (this.$refs.emailAddress as any).validate();
    }
    if (this.$refs?.passwords && (this.$refs?.passwords as any).validate) {
      (this.$refs.passwords as any).validate();
    }
    (this.$refs.form as VForm).validate();

    await Vue.nextTick();
    await this.BasicInfoFunctions.validEmailAdress(this);
  }

  error(error: string) {
    this.backendError = error;
    (this.$refs.form as VForm).validate();
    this.$store.commit("app/setStatus", null);
  }

  async nextStep(): Promise<void> {
    this.$store.commit("registration/setForm", this.form);
    this.$store.commit("app/setStatus", null);
    await this.$store.dispatch("registration/nextStep");
  }
}
